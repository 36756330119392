import { useRequiredValidator } from '@ornikar/react-validators';
import { useMemo } from 'react';
import type { ReactNode } from 'react';
import { Driver } from '../../../../fsm/types';
import { Field } from '../../../Field';
import type { LocalAutoCompleteInputProps } from '../../../LocalAutocompleteInput';
import { LocalAutoCompleteInput } from '../../../LocalAutocompleteInput';
import { TooltipReleveInformation } from '../../../TooltipReleveInformation';
import type { PrimaryOrSecondaryScreenTemplateType } from '../../../drivingSection/types';
import { ScreenTemplateWithValidation } from '../../ScreenTemplateWithValidation';
import { getCrmOptions } from './getCrmOptions';

interface InformationBonusMalusTemplateProps extends PrimaryOrSecondaryScreenTemplateType {
  licenseDate?: string;
}

export function InformationBonusMalusTemplate({
  driverType,
  licenseDate,
}: InformationBonusMalusTemplateProps): ReactNode {
  const fieldName = `${driverType}Driver.crm`;
  const isPrimaryDriver = driverType === Driver.Primary;
  const crmOptions = useMemo(() => getCrmOptions(licenseDate), [licenseDate]);
  const requiredValidator = useRequiredValidator();

  return (
    <ScreenTemplateWithValidation
      fieldNames={[fieldName]}
      title={isPrimaryDriver ? <>Quel est votre bonus malus&nbsp;?</> : <>Quel est son bonus malus&nbsp;?</>}
      highlightTitle="Où trouver votre bonus malus ?"
      highlightContent={
        <div>
          {isPrimaryDriver ? 'Votre' : 'Son'} taux de bonus malus est inscrit sur {isPrimaryDriver ? 'votre' : 'son'}{' '}
          Relevé{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            d&apos;Information <TooltipReleveInformation />
          </span>
          <br />
          <br />
          Ces données sont vérifiées lors de l&apos;envoi des pièces justificatives obligatoires demandées par votre
          assureur.
        </div>
      }
    >
      <Field<any, LocalAutoCompleteInputProps<number>>
        component={LocalAutoCompleteInput}
        name={fieldName}
        type="number"
        choices={crmOptions}
        placeholder="Ex: 1.00, ni bonus, ni malus"
        validate={requiredValidator}
        testID="autocomplete.InformationBonusMalusTemplate.crm"
      />
    </ScreenTemplateWithValidation>
  );
}
