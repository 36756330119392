import { Input } from '@ornikar/kitt';
import { Typography } from '@ornikar/kitt-universal';
import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { listModelsWithPossibleValues } from '../../../apis/vehicules';
import type { FieldProps } from '../../../components/Field';
import { FieldV2 } from '../../../components/FieldV2';
import type { LocalAutoCompleteInputProps } from '../../../components/LocalAutocompleteInput';
import { LocalAutoCompleteInput } from '../../../components/LocalAutocompleteInput';
import type { VehicleFormValues } from '../../../forms/answers';
import { useAsync } from '../../../hooks/useAsync';
import { serviceUnavailable } from '../../../utils/errorMessages';
import { sendNoOptionsFoundEvent } from '../../../utils/mixpanel';

interface ModeleAutocompleteProps extends Pick<FieldProps<string>, 'label' | 'name' | 'validate'> {
  disabled?: boolean;
}

export function ModeleAutocomplete(props: ModeleAutocompleteProps): ReactNode {
  const { values } = useFormState<VehicleFormValues>({ subscription: { values: true } });
  const { reset, change } = useForm<VehicleFormValues>();

  const [models, modelsError, modelsLoading] = useAsync(() => {
    if (!props.disabled && values.vehiculeMarque) {
      return listModelsWithPossibleValues(values.vehiculeMarque);
    }

    return Promise.resolve([]);
  }, [values.vehiculeMarque, props.disabled]);

  const modelsChoices = useMemo(() => {
    return models?.map((model) => ({ label: model.name, value: model.name })) || [];
  }, [models]);

  const vehicleModel = useMemo(() => values[props.name as keyof VehicleFormValues], [values, props.name]);

  useEffect(() => {
    if (!modelsLoading && (!models || models.length === 0)) {
      sendNoOptionsFoundEvent(props.name as keyof VehicleFormValues);
    }

    if (models && vehicleModel) {
      const pickedModel = models?.find((model) => model.name === vehicleModel);

      change('availableCarburants', pickedModel?.energies || []);
      change('availablePuissanceFiscales', pickedModel?.puissances_fiscales || []);
    }
  }, [models, vehicleModel, change, modelsLoading, props.name]);

  if (modelsError) {
    return (
      <>
        <FieldV2 {...props} disabled component={Input} />
        <Typography.Text color="kitt.danger">{serviceUnavailable}</Typography.Text>
      </>
    );
  }

  if (props.disabled) {
    return <FieldV2 {...props} component={Input} />;
  }

  return (
    <FieldV2<string, LocalAutoCompleteInputProps<string>>
      {...props}
      submitOnChange
      component={LocalAutoCompleteInput}
      isLoading={modelsLoading}
      placeholder={modelsLoading ? 'Chargement...' : ''}
      choices={modelsChoices}
      testID="autocomplete.ModeleAutocomplete.vehicleModel"
      onChange={(value) => {
        if (value !== vehicleModel) {
          const pickedModel = models?.find((model) => model.name === value);
          reset({
            vehiculeMarque: values.vehiculeMarque,
            [props.name]: value,
            availablePuissanceFiscales: pickedModel?.puissances_fiscales || [],
            availableCarburants: pickedModel?.energies || [],
          });
        }
      }}
    />
  );
}
