import AdyenCheckout from '@adyen/adyen-web';
import { Button, LoaderIcon, Typography, View } from '@ornikar/kitt-universal';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { OrnikarPackageLevel } from '../../../apis/types/Formula';
import { CallbackModule } from '../../../components/CallbackModule';
import { Footer } from '../../../components/Footer';
import { ScreenTemplateWithSideAndBottomBars } from '../../../components/ScreenTemplates/ScreenTemplateWithSideAndBottomBars';
import { CREDIT_CARD_SCREEN_UPDATE, WEB_CALLBACK_FEATURE } from '../../../constants/flagshipKeys';
import { formatPrice, useSubscriptionFsmDispatch, useSubscriptionFsmState } from '../../../fsm/context';
import { Event } from '../../../fsm/types';
import { useInsuranceDesktopMediaQuery } from '../../../hooks/useInsuranceDesktopMediaQuery';
import { useFsFlag } from '../../../setup/flagship';
import { generateCheckoutConfig } from './checkoutConfiguration';

export function CreditCardPaymentWithAdyenScreen(): ReactNode {
  const webCallbackFlag = useFsFlag<boolean>(WEB_CALLBACK_FEATURE, false);
  const subscriptionCartFlowFlag = useFsFlag<boolean>(CREDIT_CARD_SCREEN_UPDATE, false);
  const isDesktop = useInsuranceDesktopMediaQuery();
  const adyenDropInRef = useRef<HTMLDivElement>(null);
  const [isRetryButtonVisible, setIsRetryButtonVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amountValue, setAmountValue] = useState<number | null>(null);
  const send = useSubscriptionFsmDispatch();
  const dropin = useRef<any>();

  const conversionValueRef = useRef<string>();

  const {
    context: { tarificationResult, paymentPeriodicity, formulePickedObject },
  } = useSubscriptionFsmState();

  const onSuccess = useCallback((): void => {
    setIsLoading(false);
    send(Event.PAYMENT_SUCCESS, {
      conversionValue: conversionValueRef.current,
    });
  }, [send]);

  const onError = useCallback((): void => {
    send(Event.PAYMENT_ERROR, {
      conversionValue: conversionValueRef.current,
    });
    setIsLoading(false);
    setIsRetryButtonVisible(true);
  }, [send]);

  const mountDropin = useCallback(async (): Promise<void> => {
    if (!tarificationResult?.quoteId || !adyenDropInRef.current) {
      return;
    }

    setIsLoading(true);

    const checkoutConfiguration = await generateCheckoutConfig(tarificationResult.quoteId, onSuccess, onError);
    const { create, options } = await AdyenCheckout(checkoutConfiguration);

    if (dropin.current) {
      dropin.current.remove();
    }

    if (options.amount) {
      setAmountValue(options.amount.value);
      conversionValueRef.current = formatPrice(options.amount.value);
    }

    dropin.current = create('dropin', { openFirstPaymentMethod: true }).mount(adyenDropInRef.current);

    setIsRetryButtonVisible(false);
    setIsLoading(false);
  }, [onError, onSuccess, tarificationResult?.quoteId]);

  useEffect(() => {
    mountDropin();
  }, [mountDropin]);

  return (
    <>
      <ScreenTemplateWithSideAndBottomBars
        hideSaveQuoteButton
        hideSubmitButton
        disableForm
        sideBar={
          isDesktop && webCallbackFlag.getValue() ? (
            <View>
              <CallbackModule light />
            </View>
          ) : null
        }
        title={
          subscriptionCartFlowFlag.getValue()
            ? `Dernière étape ! Validez votre contrat en réalisant votre premier paiement${
                amountValue !== null ? ` de ${formatPrice(amountValue)}` : ''
              }`
            : 'Tout est bon, vous n’avez plus qu’à effectuer votre premier règlement par carte'
        }
      >
        {paymentPeriodicity === 'monthly' && subscriptionCartFlowFlag.getValue() ? (
          <View marginTop="kitt.4" marginBottom="kitt.8">
            <Typography.Text variant="regular" color="black-light" base="body-small" textAlign="center">
              Ce premier paiement correspond à vos{' '}
              <Typography.Text variant="bold" color="black-light" base="body-small" textAlign="center">
                2 premières mensualités de contrat avec options
                {formulePickedObject?.name !== OrnikarPackageLevel.THIRD_PARTY && ' et la taxe attentat'}.
              </Typography.Text>
            </Typography.Text>
          </View>
        ) : null}

        <View
          position="relative"
          display="flex"
          justifyContent="center"
          backgroundColor="kitt.white"
          borderRadius="kitt.5"
          padding={isDesktop ? 'kitt.6' : 'kitt.4'}
          paddingBottom="kitt.4"
          borderColor="kitt.palettes.lateOcean.black100"
          borderWidth={1}
          borderStyle="solid"
          marginBottom={isDesktop ? 'kitt.16' : 0}
          zIndex={1}
        >
          <div className="adyen-custom-bottom-bar" />

          <View ref={adyenDropInRef} width="100%">
            {isRetryButtonVisible && (
              <View position="absolute" bottom="kitt.4" left="kitt.4">
                <Button
                  type="primary"
                  disabled={isLoading}
                  icon={isLoading ? <LoaderIcon /> : undefined}
                  onPress={mountDropin}
                >
                  Réessayer
                </Button>
              </View>
            )}
          </View>
        </View>

        {!isDesktop && webCallbackFlag.getValue() ? (
          <View marginTop="kitt.4" zIndex={-1}>
            <CallbackModule light />
          </View>
        ) : null}
      </ScreenTemplateWithSideAndBottomBars>
      <Footer />
    </>
  );
}
