import { Button } from '@ornikar/kitt';
import { Typography, VStack, View } from '@ornikar/kitt-universal';
import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from '../../../../components/Field';
import { LocalInputAutoComplete } from '../../../../components/LocalAutocompleteInput';
import { useHamonNameValidator } from '../../../../forms/validation/sections/insurance/hamon';

interface EmptyDropdownContentProps {
  onAddInsurerPress: () => void;
}

function EmptyDropdownContent({ onAddInsurerPress }: EmptyDropdownContentProps): ReactNode {
  return (
    <VStack paddingY="kitt.2" space="kitt.3">
      <View paddingX="kitt.4">
        <Typography.Text>
          <FormattedMessage
            id="hamonForm.insurerNotInList"
            defaultMessage="Il semble que votre assureur n’est pas dans notre liste. Vérifiez son orthographe ou ajoutez le manuellement."
          />
        </Typography.Text>
      </View>
      {/* Here we use the legacy 'kitt' button because we want to use the onMouseDown event
      that does not exist on kitt-universal's button which only have the OnPress prop.
      OnMouseDown is triggered when the button is pressed/clicked, whereas OnPress is triggered
      when the button is pressed/clicked and then released. The problem is that here, the button
      is in a modal and also triggers a blur event. Using OnMouseDown allows us to perform
      some actions before the blur event occurs with the help of a setTimeout from
      src/components/LocalAutocompleteInput/index.tsx activated by the delayCloseOnBlur prop. */}
      <Button icon="plus" type="subtle" onMouseDown={onAddInsurerPress}>
        <FormattedMessage id="hamonForm.addMyInsurer" defaultMessage="Ajouter mon assureur" />
      </Button>
    </VStack>
  );
}

interface InsurerNameAutoCompleteProps {
  isLoading: boolean;
  choices: {
    label: string;
    value: string;
  }[];
  onAddInsurerPress: () => void;
  onInputValueChange?: (value: string) => void;
  onChange?: (value: string | number) => void;
}

export function InsurerNameAutoComplete({
  isLoading,
  choices,
  onAddInsurerPress,
  onInputValueChange,
  onChange,
}: InsurerNameAutoCompleteProps): ReactNode {
  const hamonNameValidator = useHamonNameValidator();
  return (
    <Field
      label="Nom de votre assureur"
      name="hamonName"
      component={LocalInputAutoComplete<string>}
      isLoading={isLoading}
      emptyComponent={<EmptyDropdownContent onAddInsurerPress={onAddInsurerPress} />}
      validate={hamonNameValidator}
      choices={choices}
      onCustomInputValueChange={onInputValueChange}
      onChange={onChange}
    />
  );
}
